import Service from '../Service'
import { LandingV4Instance } from './Instance'

 /**
 * @typedef {Object} PldData
 * @property {String} creditApplicationId
 * @property {Boolean} mortgageCredit
 * @property {Boolean} vehicleCredit
 * @property {Boolean} creditCard
 * @property {String} cardNumber
 */

/**
 * @class
 * @param  {String} creditApplicationId
 */

export class Authenticated extends Service {
    constructor(creditApplicationId) {
        super()
        this._creditApplicationId = `${creditApplicationId}`
    }

/**
 * @param  {String} landingApplicationId
 */
    setValidation(mortgageCredit, vehicleCredit, creditCard,cardNumber) {
        this._mortgageCredit = !!mortgageCredit
        this._vehicleCredit = !!vehicleCredit
        this._creditCard = !!creditCard
        this._cardNumber = `${cardNumber}`
    }

    /**
   * @returns {PldData}
   */
    get data () {
        return {
            creditApplicationId: `${this._creditApplicationId}`,
            mortgageCredit: this._mortgageCredit,
            vehicleCredit: this._vehicleCredit,
            creditCard: this._creditCard,
            cardNumber: `${this._cardNumber}`
        }
    }
    
  /**
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
        send () {
            const self = this
            return this._requestWrapper(LandingV4Instance({
                method: 'PUT',
                url: '/prescore/authenticate',
                data: self.data,
                timeout: 180000,
            }))
        }  
}